import React, { useState } from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import { css } from '@emotion/react'
import { Menu } from '@headlessui/react'

import { ButtonStyle, CopyLGStyle, CopyStyle } from './UI'
import ModalForHubspotHealthcareForm from './ModalForHubspotHealthcareForm'
// Assets
import logo from '../images/staffy-wordmark-orange.svg'
import newlogo from '../images/staffy-new-logo.webp'
import {
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icons = {
  faArrowDown: faAngleDown,
  faAngleUp: faAngleUp
};

const Navigation = () => {
  const [expanded, setExpanded] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [isOurSolutionsAccordionOpen, setIsOurSolutionsAccordionOpen] = useState(false);
  const [isJobsAccordionOpen, setIsJobsAccordionOpen] = useState(false);

  const toggleOurSolutionsAccordion = () => {
    setIsOurSolutionsAccordionOpen(!isOurSolutionsAccordionOpen);
  };

  const toggleJobsAccordion = () => {
    setIsJobsAccordionOpen(!isJobsAccordionOpen);
  };

  const toggleMobileNav = () => {
    setExpanded(!expanded)
    setIsOurSolutionsAccordionOpen(false);
    setIsJobsAccordionOpen(false)
  }

  return (
    <>
      <ModalForHubspotHealthcareForm isOpen={modalOpen} handleClose={() => setModalOpen(false)} />
      <nav
        css={[
          tw`fixed w-full max-h-screen bg-white flex flex-col z-50 lg:(w-full static block h-auto)`,
          expanded && tw`h-screen`,
        ]}
      >
        {/* Main Nav */}
        <div tw="w-full flex justify-between h-20">
          {/* Internal Links */}
          <div tw="ml-4 lg:ml-4">
            <ul css={[ButtonStyle, tw`h-full flex items-center text-center`]}>
              <li tw="lg:mr-3">
                <Link to="/">
                  <img width='190px' height='80px' src={newlogo} css={tw`max-w-[190px] w-full`} />
                </Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/start-earning">Start earning</Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/find-talent">Find talent</Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] ">
                <Menu as="div">
                  <Menu.Button tw='flex items-center hover:text-[#FF4D00]'>
                    <span>Solutions</span> <FontAwesomeIcon icon={icons.faArrowDown} tw='ml-[5px] text-[12px]' />
                  </Menu.Button>
                  <Menu.Items tw="absolute bg-white border border-black rounded mt-[5px] text-left" style={{ zIndex: 9 }}>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="https://staffy.com" target="_blank">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Marketplace
                        </div>
                      </Menu.Item>
                    </a>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="https://salus.staffy.com" target="_blank">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Credential Management
                        </div>
                      </Menu.Item>
                    </a>
                  </Menu.Items>
                </Menu>
              </li>
              {/* <li tw="mr-4 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/jobs">Jobs</Link>
              </li> */}
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] ">
                <Menu as="div">
                  <Menu.Button tw='flex items-center hover:text-[#FF4D00]'>
                    <span>Jobs</span> <FontAwesomeIcon icon={icons.faArrowDown} tw='ml-[5px] text-[12px]' />
                  </Menu.Button>
                  <Menu.Items tw="absolute bg-white border border-black rounded mt-[5px] text-left" style={{ zIndex: 9 }}>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="/jobs">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          All Jobs
                        </div>
                      </Menu.Item>
                    </a>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="/jobs/registered-nurse">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Registered Nurse
                        </div>
                      </Menu.Item>
                    </a>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="/jobs/registered-practical-nurse">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Registered Practical Nurse
                        </div>
                      </Menu.Item>
                    </a>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="/jobs/personal-support-worker">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Personal Support Worker
                        </div>
                      </Menu.Item>
                    </a>
                    <a css={[tw`hover:text-[#FF4D00]`]} href="/jobs/personal-care-aide">
                      <Menu.Item css={[tw`cursor-pointer`]}>
                        <div css={[tw`mb-0 mt-0 px-4 py-3 text-[14px]`]}>
                          Personal Care Aide
                        </div>
                      </Menu.Item>
                    </a>
                  </Menu.Items>
                </Menu>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/about">About</Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/faq">FAQ</Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/blog">Blog</Link>
              </li>
              <li tw="mr-3 hidden lg:block transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          {/* External CTAs */}
          <div tw="hidden lg:block">
            <ul css={[ButtonStyle, tw`h-full flex items-center text-center`]}>
              {/* Partner login */}
              <li tw="px-8">
                <a
                  href="https://app.staffy.com/auth/login"
                  target="_blank"
                  rel="noreferrer"
                  tw="transition-property[color] transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:text-[#FF4D00]"
                >
                  Partner&nbsp;login
                </a>
              </li>
              <li tw="h-full">
                <Link
                  tw="w-full h-full bg-[#FF4D00] text-white px-14 flex justify-center items-center"
                  to="/find-talent/#book-a-demo"
                >
                  Book a demo
                </Link>

              </li>
              {/* Send app */}
              <li tw="h-full">
                <Link
                  tw="w-full h-full bg-[#1f2c3a] text-white px-14 flex justify-center items-center"
                  to="/app"
                >
                  Download the app
                </Link>
              </li>
            </ul>
          </div>
          {/* Mobile Hamburger Icon */}
          <div tw="mr-4 lg:hidden flex items-center">
            {!expanded && (
              <button onClick={toggleMobileNav}>
                <hr tw="w-7 mb-2 border-black" />
                <hr tw="w-7 my-2 border-black" />
                <hr tw="w-7 mt-2 border-black" />
              </button>
            )}

            {expanded && (
              <button onClick={toggleMobileNav}>
                <hr
                  css={[
                    tw`w-7 border-black`,
                    css`
                      transform: rotate(45deg);
                    `,
                  ]}
                />
                <hr
                  css={[
                    tw`w-7 border-black`,
                    css`
                      transform: rotate(-45deg);
                    `,
                  ]}
                />
              </button>
            )}
          </div>
        </div>
        {/* Expanded Nav */}
        <div
          css={[
            tw`hidden flex-grow flex-col justify-between bg-white overflow-auto lg:hidden`,
            expanded && tw`flex`,
          ]}
        >
          {/* Mobile - Internal Links */}
          <div>
            <ul css={[CopyStyle]}>
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/start-earning" onClick={toggleMobileNav}>
                  Start earning
                </Link>
              </li>
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/find-talent" onClick={toggleMobileNav}>
                  Find talent
                </Link>
              </li>
              <li tw="border-t-[1px] border-black" onClick={toggleOurSolutionsAccordion}>
                <button tw="block pl-8 py-4" >
                  Our Solutions {isOurSolutionsAccordionOpen ?
                    <FontAwesomeIcon icon={icons.faAngleUp} tw='ml-[5px] text-[12px]' /> :
                    <FontAwesomeIcon icon={icons.faArrowDown} tw='ml-[5px] text-[12px]' />}

                </button>
              </li>
              {isOurSolutionsAccordionOpen && (
                <ul css={[CopyStyle]}>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="https://staffy.com" target="_blank">
                      Marketplace
                    </a>
                  </li>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="https://salus.staffy.com" target="_blank">
                      Credential Management
                    </a>
                  </li>
                </ul>
              )}
              <li tw="border-t-[1px] border-black" onClick={toggleJobsAccordion}>
                <button tw="block pl-8 py-4" >
                  Jobs {isJobsAccordionOpen ?
                    <FontAwesomeIcon icon={icons.faAngleUp} tw='ml-[5px] text-[12px]' /> :
                    <FontAwesomeIcon icon={icons.faArrowDown} tw='ml-[5px] text-[12px]' />}

                </button>
              </li>
              {isJobsAccordionOpen && (
                <ul css={[CopyStyle]}>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="/jobs">
                      All Jobs
                    </a>
                  </li>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="/jobs/registered-nurse">
                      Registered Nurse
                    </a>
                  </li>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="/jobs/registered-practical-nurse">
                      Registered Practical Nurse
                    </a>
                  </li>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="/jobs/personal-support-worker">
                      Personal Support Worker
                    </a>
                  </li>
                  <li tw="border-t-[1px] border-black">
                    <a css={[tw`block pl-8 py-4 text-[14px]`]} href="/jobs/personal-care-aide">
                      Personal Care Aide
                    </a>
                  </li>
                </ul>
              )}
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/about" onClick={toggleMobileNav}>
                  About
                </Link>
              </li>
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/blog" onClick={toggleMobileNav}>
                  Blog
                </Link>
              </li>
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/contact" onClick={toggleMobileNav}>
                  Contact
                </Link>
              </li>
              <li tw="border-t-[1px] border-black">
                <Link tw="block pl-8 py-4" to="/faq" onClick={toggleMobileNav}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          {/* Mobile - External CTAs */}
          <div>
            <ul css={[CopyStyle, tw`flex flex-col items-center`]}>
              {/* Partner login */}
              <li tw="w-full">
                <a
                  tw="w-full bg-[#F1FAFD] text-black px-8 py-4 flex justify-center items-center"
                  href="https://app.staffy.com/auth/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Partner&nbsp;login
                </a>
              </li>
              <li tw="w-full">
                <Link
                  tw="w-full bg-[#FF4D00] text-white px-8 py-6 flex justify-center items-center"
                  to="/find-talent/#book-a-demo"
                  onClick={toggleMobileNav}
                >
                  Book a demo
                </Link>
              </li>
              {/* Send app */}
              <li tw="w-full">
                <Link
                  tw="w-full bg-[#1f2c3a] text-white px-8 py-6 flex justify-center items-center"
                  to="/app"
                >
                  Download the app
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav >
      {/* Mobile - Top spacer behind fixed nav */}
      < div tw="block lg:hidden w-full h-20" ></div >
    </>
  )
}

export default Navigation
